import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import gsap from 'gsap';
import LokaltogContext from '../context/context';
// import { IHeaderNavigationItem } from '../types/heartcore.types';
import LinkWithPageTransition from './link-with-page-transition';
// import { AccordionItem } from './accordion';
import ArrowIcon from './svg/arrow-icon';
import { timeout } from '../utils/utils';
import { getCleanUrl } from '../utils/shared-build-runtime-utils';
export const MOBILEMENUBREAKPOINT = 1149;

type MenuItem = {
	name: string
	url: string | null
	submenu: MenuItem[] | null
	level: number
	rootURL: string
}

const NavigationItem = ({ name, url, submenu, level }: MenuItem): JSX.Element => {
	const style = { '--level': level } as React.CSSProperties;
	const submenuRef = useRef<HTMLDivElement>(null);

	const handleMouseEnter = () => {
		if(submenuRef.current) {
			gsap.to(submenuRef.current, { height: '4.275rem', duration: .3 });
		}
	};

	const handleMouseLeave = () => {
		if(submenuRef.current) {
			gsap.to(submenuRef.current, {
				height: 0,
				duration: .3,
			});
		}
	};

	useEffect(() => {
		if(submenuRef.current) {
			gsap.set(submenuRef.current, { top: `${level * 4.275}rem`});
		}
	}, []);

	return (
		<li
			className={clsx('header__navigation-item')}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{url === null ? (
				<span className='header__navigation-item-caption'>{name}</span>
			) : (
				<LinkWithPageTransition
					classes='link-with-underline'
					activeClass='link-with-underline--active'
					url={url}
				>
					{name}
				</LinkWithPageTransition>
			)}
			{submenu !== null && submenu.length > 0 && (
				<div ref={submenuRef} className={clsx('header__sub-wrapper')} style={style}>
					<ul className='header__subnavigation-inner-wrapper box-wide'>
						{submenu.map((child, idx) => <NavigationItem key={idx} {...child} level={level + 1}/>)}
					</ul>
				</div>
			)}
		</li>
	);
};

const MobileSubmenuItem = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const itemRef = useRef<HTMLDivElement>(null);
	const toggle = () => setIsOpen(!isOpen);

	useEffect(() => {
		if(!itemRef.current) { return; }
		const root = gsap.utils.selector(itemRef.current);

		if(isOpen) {
			gsap.to(root('.header--mobile__submenu-wrapper')[0], { height: 'auto', duration: .3 });
		} else {

			gsap.to(root('.header--mobile__submenu-wrapper')[0], { height: 0, duration: .3 });
		}
	}, [isOpen]);

	const isCurrent = location.pathname === getCleanUrl(props.url, props.rootURL);

	return (
		<li className='header__mobile-navigation-item'>
			{props.url === null ? (
				<button
					onClick={toggle}
					type='button'
					aria-label='open'
					className={clsx('accordion__button', {
						'accordion__button--closed': !isOpen,
						'accordion__button--open': isOpen,
					})}
				>
					<p className='accordion__display-text header__mobile-according'>
						{props.name}
					</p>
					<div className='accordion__icon header__mobile-accordion-icon'/>
				</button>
			) :
				<LinkWithPageTransition
					activeClass='header__mobile-navigation-item'
					url={props.url}
				>
					{isCurrent ? <figure className='link-icon'><ArrowIcon /></figure> : null}{props.name}
				</LinkWithPageTransition>
			}
			{props.submenu && (
				<div ref={itemRef}>
					<div className='header--mobile__submenu-wrapper' style={{ height: 0, overflow: 'hidden', padding: 0 }}>
						<ul className='header__subnavigation' >
							{props.submenu.map(el => (
								<MobileSubmenuItem key={el.name} {...el} />
							))}
						</ul>
					</div>
				</div>
			)}
		</li>
	);
};

const MobileMenuItem = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const itemRef = useRef<HTMLDivElement>(null);
	const toggle = () => setIsOpen(!isOpen);

	useEffect(() => {
		if(!itemRef.current) { return; }
		const root = gsap.utils.selector(itemRef.current);

		if(isOpen) {
			gsap.to(root('.header--mobile__submenu-wrapper')[0], { height: 'auto', duration: .3 });
		} else {

			gsap.to(root('.header--mobile__submenu-wrapper')[0], { height: 0, duration: .3 });
		}
	}, [isOpen]);

	const isCurrent = location.pathname === getCleanUrl(props.url, props.rootURL);

	return (
		<li className='header__mobile-navigation-item'>
			{props.url === null ? (
				<button
					onClick={toggle}
					type='button'
					aria-label='open'
					className={clsx('accordion__button', {
						'accordion__button--closed': !isOpen,
						'accordion__button--open': isOpen,
					})}
				>
					<p className='accordion__display-text header__mobile-according'>
						{props.name}
					</p>
					<div className='accordion__icon header__mobile-accordion-icon'/>
				</button>
			) :
				<LinkWithPageTransition
					activeClass='header__mobile-navigation-item'
					url={props.url}
				>
					{isCurrent ? <figure className='link-icon'><ArrowIcon /></figure> : null}{props.name}
				</LinkWithPageTransition>
			}
			{props.submenu && (
				<div ref={itemRef}>
					<div className='header--mobile__submenu-wrapper' style={{ height: 0, overflow: 'hidden', padding: 0 }}>
						<ul className='header__subnavigation' >
							{props.submenu.map(el => (
								<MobileSubmenuItem key={el.name} {...el} rootURL={props.rootURL}/>
							))}
						</ul>
					</div>
				</div>
			)}
		</li>
	);
};

const MobileMenu = (props) => {
	const { navigation, rootURL } = props;
	const menuItems = navigation.menu[0].submenu.concat(navigation.menu[1].submenu);

	return (
		<div className='header--mobile_inner'>
			<ul>
				{menuItems.map((el: MenuItem, idx: number) => (
					<MobileMenuItem key={idx} {...el} rootURL={rootURL}/>
				))}
				<li className='header__navigation-item--icon'>
					<LinkWithPageTransition url='/international/english'>
						<figure style={{width: '24px'}}>
							<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
								<path fill="#012169" d="M0 0h640v480H0z"/>
								<path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"/>
								<path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"/>
								<path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z"/>
								<path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z"/>
							</svg>
						</figure>
					</LinkWithPageTransition>
				</li>
				<li className='header__navigation-item--icon'>
					<LinkWithPageTransition url='/international/deutsch'>
						<figure style={{width: '24px'}}>
							<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" viewBox="0 0 640 480">
								<path fill="#fc0" d="M0 320h640v160H0z"/>
								<path fill="#000001" d="M0 0h640v160H0z"/>
								<path fill="red" d="M0 160h640v160H0z"/>
							</svg>
						</figure>
					</LinkWithPageTransition>
				</li>
			</ul>
		</div>
	);
};

// const HeaderNavigationItem = ({ item, windowWidth, menuIsVisible }: { item: any, windowWidth: number, menuIsVisible: boolean }) => {
// 	const withSubnavigation = Boolean(item.navigation?.length);
// 	const [currentPathGetter, setCurrentPathGetter] = useState(() => '');
// 	const lokaltogContext = useContext(LokaltogContext);
// 	useEffect(() => {
// 		setCurrentPathGetter(() => location.pathname);
// 	}, []);

// 	if (!withSubnavigation && windowWidth > MOBILEMENUBREAKPOINT) {
// 		return (
// 			<li key={item.id} className='header__navigation-item'>
// 				<LinkWithPageTransition
// 					classes='link-with-underline'
// 					activeClass='link-with-underline--active'
// 					url={item.url}
// 				>
// 					{item.name}
// 				</LinkWithPageTransition>
// 			</li>
// 		);
// 	}

// 	const isCurrent = (url) => {
// 		return currentPathGetter === getCleanUrl(url, lokaltogContext.rootNodeUrl);
// 	};

// 	const parentLinkIsActive = isCurrent(item.url);
// 	if (!withSubnavigation && windowWidth <= MOBILEMENUBREAKPOINT) {
// 		return (
// 			<li key={item.id} className={`header__navigation-item ${parentLinkIsActive ? 'header__navigation-item--with-icon': ''}`}>
// 				{parentLinkIsActive ? <div className='link-icon'><ArrowIcon /> </div> : null}
// 				<LinkWithPageTransition url={item.url}>{item.name}</LinkWithPageTransition>
// 			</li>
// 		);
// 	}

// 	if(windowWidth <= MOBILEMENUBREAKPOINT) {
// 		return (
// 			<AccordionItem headline={item.name} openByDefault={menuIsVisible && (parentLinkIsActive || (item.navigation && !!item.navigation.find(childPage => isCurrent(childPage.url))))}>
// 				<ul className='header__subnavigation'>
// 					{item.navigation.map(childPage => (
// 						<li key={childPage.udi} className={`header__navigation-item ${isCurrent(childPage.url) ? 'header__navigation-item--with-icon': ''}`}>
// 							{isCurrent(childPage.url) ? <div className='link-icon'><ArrowIcon /></div> : null}
// 							<LinkWithPageTransition url={childPage.url}>{childPage.name}</LinkWithPageTransition>
// 						</li>
// 					))}
// 				</ul>
// 			</AccordionItem>
// 		);
// 	}

// 	return (
// 		<li key={item.id} className='header__navigation-item'>
// 			<div className='header__navigation-item-caption'>{item.name}</div>
// 			<div className='header__subnavigation'>
// 				<ul className='header__subnavigation-inner-wrapper box-wide'>
// 					{/* <li key={item.id} className='header__navigation-item'>
// 						<LinkWithPageTransition classes='link-with-underline' activeClass='link-with-underline--active' url={item.url}>
// 							{item.name}
// 						</LinkWithPageTransition>
// 					</li> */}
// 					{item.navigation && item.navigation.map(childPage => (
// 						<li key={childPage.udi} className='header__navigation-item'>
// 							<LinkWithPageTransition classes='link-with-underline' activeClass='link-with-underline--active' url={childPage.url}>
// 								{childPage.name}
// 							</LinkWithPageTransition>
// 						</li>
// 					))}
// 				</ul>
// 			</div>
// 		</li>
// 	);
// };

const Header = () => {
	const { data } = useStaticQuery(graphql`
		query {
			data: globalContent {
				rootNodeUrl
				navigation {
					name
					menu {
						name
						url
						submenu {
							name
							url
							submenu {
								name
								url
								submenu {
									name
									url
									submenu {
										name
										url
										submenu {
											name
											url
											submenu {
												name
												url
											}
										}
									}
								}
							}
						}
					}
				}
				header {
					logo {
						url
					}
					primaryNavigation {
						id
						name
						url
					}
					secondaryNavigation {
						id
						name
						url
					}
				}
			}
		}
	`);

	if(!data) { return <></>; }

	const { header: { logo }, navigation } = data;

	const [ menuIsOpen, setMenuIsOpen ] = useState(false);
	const [ menuIsVisible, setMenuIsVisible ] = useState(false);
	const [ documentScrollOffset, setDocumentScrollOffset ] = useState(0);
	const lokaltogContext = useContext(LokaltogContext);

	const onToggleMenuHandler = () => {
		if (menuIsOpen) {
			closeMenu();
		} else {
			setMenuIsVisible(true);
		}
	};

	const closeMenu = () => {
		document.body.classList.remove('body--fixed');
		document.documentElement.scrollTo(0, documentScrollOffset);

		setMenuIsOpen(false);
	};

	useEffect(() => {

		const setCloseMenu = async() => {
			if (!menuIsOpen && menuIsVisible) {
				await timeout(300);
				setMenuIsVisible(false);
			} else if (menuIsOpen && menuIsVisible) {
				await timeout(300);
				setDocumentScrollOffset(document.documentElement.scrollTop);
				document.body.classList.add('body--fixed');
			}
		};

		setCloseMenu();
	}, [menuIsOpen]);

	useEffect(() => {
		if (menuIsVisible && !menuIsOpen) {

			const setOpenMenu = async() => {
				await timeout(100);
				setMenuIsOpen(true);
			};

			setOpenMenu();
		}

	}, [menuIsVisible]);

	useEffect(() => {
		if (lokaltogContext.windowWidth > MOBILEMENUBREAKPOINT && menuIsOpen) {
			closeMenu();
		}

	}, [lokaltogContext.windowWidth]);

	return (
		<header className={`header ${menuIsVisible ? 'header--menu-is-visible' : ''} ${menuIsOpen ? 'header--menu-is-open' : ''}`}>
			<div className='header__background'></div>
			<nav className={`header__inner-wrapper ${lokaltogContext.windowWidth > MOBILEMENUBREAKPOINT ? 'box-wide' : 'header__inner-wrapper--mobile'}`}>
				<ul className="header__navigation header__navigation--primary">
					{navigation.menu[0].submenu.map((el: MenuItem, idx: number) => (
						<NavigationItem name={el.name} url={el.url} submenu={el.submenu} key={idx} level={1} />
					))}
				</ul>
				{/* <ul className="header__navigation header__navigation--primary">
					{primaryNavigation?.map(pni => <HeaderNavigationItem key={pni.id} item={pni} windowWidth={lokaltogContext.windowWidth} menuIsVisible={menuIsVisible} />)}
				</ul> */}
				<ul className="header__navigation header__navigation--secondary">
					{navigation.menu[1].submenu.map((el: MenuItem, idx: number) => (
						<NavigationItem name={el.name} url={el.url} submenu={el.submenu} key={idx} level={1} rootURL={lokaltogContext.rootNodeUrl}/>
					))}
					{/* {primaryNavigation?.map(sni => <HeaderNavigationItem key={sni.id} item={sni} windowWidth={lokaltogContext.windowWidth} menuIsVisible={menuIsVisible} />)} */}

					<li className='header__navigation-item--icon'>
						<LinkWithPageTransition url='/international/english'>
							<figure style={{width: '24px'}}>
								<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
									<path fill="#012169" d="M0 0h640v480H0z"/>
									<path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"/>
									<path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"/>
									<path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z"/>
									<path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z"/>
								</svg>
							</figure>
						</LinkWithPageTransition>
					</li>
					<li className='header__navigation-item--icon'>
						<LinkWithPageTransition url='/international/deutsch'>
							<figure style={{width: '24px'}}>
								<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" viewBox="0 0 640 480">
									<path fill="#fc0" d="M0 320h640v160H0z"/>
									<path fill="#000001" d="M0 0h640v160H0z"/>
									<path fill="red" d="M0 160h640v160H0z"/>
								</svg>
							</figure>
						</LinkWithPageTransition>
					</li>
				</ul>
			</nav>

			<div className='header__logo-wrapper'>
				<LinkWithPageTransition url='/'>
					<img src={logo?.url} className='w-20' loading='eager' alt='logo' />
				</LinkWithPageTransition>
			</div>
			{lokaltogContext.windowWidth <= MOBILEMENUBREAKPOINT ? 
				<button className='header__burger-menu' onClick={onToggleMenuHandler} aria-label={!menuIsOpen ? 'Open menu' : 'Close menu'}>
					<div className='header__burger-item'></div>
					<div className='header__burger-item'></div>
					<div className='header__burger-item'></div>
				</button>
				: null
			}
			{lokaltogContext.windowWidth <= MOBILEMENUBREAKPOINT && menuIsOpen ? (
				<div className='header--mobile'>
					<MobileMenu navigation={navigation} rootURL={lokaltogContext.rootNodeUrl}/>
				</div>
			)
				: null
			}
		</header>
	);
};

export default Header;
